.App {
  display: flex;
	justify-content: center;
	align-items: center;
	height: 100vh;
	background-color: black;
}

.Placeholder {
	width: 12rem;
	height: auto;
	animation: fade 3s infinite;
}

@keyframes fade {
	0%, 100% { opacity: 0; }
	50% {opacity: 1; }
}
